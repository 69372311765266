import { marked } from "marked";

const replaceUrl = (url) =>
  url
    ? url.replace(
        "//salusstrapi.blob.core.windows.net",
        "//blogmedia.getheltia.com"
      )
    : null;

function mapImageFormatUrls(formats) {
  const thumbnailImage = formats?.thumbnail?.url;
  const smallImage = formats?.small?.url || thumbnailImage;
  const mediumImage = formats?.medium?.url || smallImage;
  const largeImage = formats?.large?.url || mediumImage;

  return {
    original: {
      large: largeImage,
      medium: mediumImage,
      small: smallImage,
      thumbnail: thumbnailImage,
    },
    converted: {
      large: replaceUrl(largeImage),
      medium: replaceUrl(mediumImage),
      small: replaceUrl(smallImage),
      thumbnail: replaceUrl(thumbnailImage),
    },
  };
}

export const mapPost = (post) => {
  try {
    const coverImageFormats = mapImageFormatUrls(post.coverImage?.formats);

    var parsedContent = marked
      .parse(post.content)
      .replace(/<a /g, '<a target="_blank" class="outer-link" ');
    var headers = parsedContent.match(/<h(.).*?<\/h\1>/g) ?? [];
    var tocids = [];
    var tocContents = [];

    var tocs = [];

    for (var i of headers) {
      var id = i.substring(i.indexOf('="') + 2, i.lastIndexOf('">'));
      tocids.push(id);
      var text = i.substring(i.indexOf('">') + 2, i.lastIndexOf("</"));
      tocContents.push(text);

      var level = i.substring(2, 3) - 3;

      tocs.push({ id, text, level });
    }

    var p = {
      id: post.id,
      slug: post.slug,
      title: post.title,
      metaTitle: post.metaTitle,
      metaDesc: post.metaDesc,
      summary: post.summary,
      content: parsedContent,
      author: post.author ?? "Heltia",
      coverImage: coverImageFormats.converted,
      coverImageOriginal: coverImageFormats.original,
      category: post.category,
      tags: post.tags.data?.map((t) => t.title),
      publishedAtIso: post.publishedAt,
      publishedAt: formatDate(post.publishedAt),
      createdAt: formatDate(post.createdAt),
      createdAtIso: post.createdAt,
      updatedAt: formatDate(post.updatedAt),
      updatedAtIso: post.updatedAt,
      approvedBy: post.approvedBy,
      tocs: tocs,
      redirectToPost: post.redirectToPost,
      blogAuthor: post.blog_author
        ? {
            ...post.blog_author,
            avatar: {
              ...post.blog_author.avatar,
              formats: mapImageFormatUrls(post.blog_author.avatar.formats),
              url: replaceUrl(post.blog_author.avatar.url),
            },
          }
        : null,
      faqs: post.faqs,
    };

    return p;
  } catch (error) {
    throw error;
  }
};

export const loadPosts = async (page, count, filter) => {
  let queryParams: any = {
    "pagination[page]": page ?? 1,
    "pagination[pageSize]": count ?? 10,
    "populate[coverImage][fields][0]": "formats",
    "populate[tags][fields][0]": "*",
    "populate[category][fields][0]": "title",
    "populate[authorAvatar][fields][0]": "*",
    "populate[blog_author][fields][0]": "*",
    "populate[blog_author][populate][avatar][fields][0]": "*",
    "sort[0]": "publishedAt:desc",
  };
  if (filter) {
    queryParams = {
      "pagination[page]": page ?? 1,
      "pagination[pageSize]": count ?? 10,
      "filters[category][slug][$eq]": filter,
      "populate[coverImage][fields][0]": "formats",
      "populate[tags][fields][0]": "*",
      "populate[category][fields][0]": "title",
      "populate[authorAvatar][fields][0]": "*",
      "populate[blog_author][fields][0]": "*",
      "populate[blog_author][populate][avatar][fields][0]": "*",
      "sort[0]": "publishedAt:desc",
    };
  }

  let params = {
    queryParams,
  };

  const result = (await $fetch("/api/posts", {
    method: "POST",
    body: JSON.stringify(params),
  })) as any;

  const filtered = result.data.map((x) => {
    return mapPost(x);
  });
  return {
    meta: result.meta,
    posts: filtered,
    hasMore:
      result.meta.pagination.page * result.meta.pagination.pageSize <
      result.meta.pagination.total,
  };
};

export const loadPost = async (page, count, filter) => {
  let queryParams: any = {
    "pagination[page]": page ?? 1,
    "pagination[pageSize]": count ?? 10,
  };
  if (filter) {
    queryParams = {
      "pagination[page]": page ?? 1,
      "pagination[pageSize]": count ?? 10,
      "filters[slug][$eq]": filter,
      "populate[coverImage][fields][0]": "formats",
      "populate[tags][fields][0]": "*",
      "populate[category][fields][0]": "*",
      "populate[authorAvatar][fields][0]": "formats",
      "populate[blog_author][fields][0]": "*",
      "populate[blog_author][populate][avatar][fields][0]": "*",
      "populate[faqs][fields][0]": "*",
      "sort[0]": "publishedAt:desc",
    };
  }

  let params = {
    queryParams,
    handleNotFound: true,
  };

  const result = (await $fetch("/api/posts", {
    method: "POST",
    body: JSON.stringify(params),
  })) as any;

  const filtered = mapPost(result.data[0]);
  return filtered;
};

export const loadCategories = async () => {
  const params = {
    "populate[test][fields]": "*",
    "populate[vertical][fields]": "*",
    "populate[vertical][populate][provider_type][fields]": "*",
    "populate[faqs][fields]": "*",
    "populate[faqs][fields][0]": "*",
    sort: "faqs.sorting:asc",
  };

  const result = (await $fetch("/api/categories", { params })) as any;
  const categories = result.data.map((category) => mapCategory(category));
  return categories;
};

const mapCategory = (category) => {
  const normalizedCategory = {
    ...category,
    faqs: category.faqs.map((faq) => ({
      ...faq,
      answerHtml: faq.answerMarkdown ? marked.parse(faq.answerMarkdown) : null,
    })),
  };

  return normalizedCategory;
};
